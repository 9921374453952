import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ImagesQuicklyCompress from 'images-quickly-compress';
import { Input, Upload, Spin, Dropdown, Button, Modal } from 'antd';
import {
  InboxOutlined,
  CheckOutlined,
  CloseOutlined,
  Loading3QuartersOutlined,
  CopyOutlined,
  DownOutlined,
} from '@ant-design/icons';
import styles from './index.module.scss';
import mdStyles from './mackdown.module.scss';
import mathjax3 from 'markdown-it-mathjax3';
import md from 'markdown-it';
import { imgOcr, imgOcrGpu, submitEditResult, getTaskResult, getTaskResultGpu } from '../../services/index';
import { fileTypeItems } from './config';
import Clipboard from 'clipboard';
import _ from 'lodash';
import { Header } from '../../components/Header';
// import { saveAs } from 'file-saver';
// import { Document, Paragraph, Packer } from 'docx';

const { Dragger } = Upload;
const { TextArea } = Input;

const Md = md({
  breaks: true,
}).use(mathjax3, {
  loader: { load: ['input/tex', 'output/chtml'] },
  tex: {
    inlineMath: [
      ['$', '$'],
      ['\\(', '\\)'],
    ],
  },
});

try {
  const defaultParagraphRenderer =
    Md.renderer.rules.paragraph_open || ((tokens, idx, options, env, self) => self.renderToken(tokens, idx, options));
  Md.renderer.rules.paragraph_open = function (tokens, idx, options, env, self) {
    let result = '';
    if (idx > 1) {
      const inline = tokens[idx - 2];
      const paragraph = tokens[idx];
      if (inline.type === 'inline' && inline.map && inline.map[1] && paragraph.map && paragraph.map[0]) {
        const diff = paragraph.map[0] - inline.map[1];
        if (diff > 0) {
          result = '<br>'.repeat(diff);
        }
      }
    }
    return result + defaultParagraphRenderer(tokens, idx, options, env, self);
  };
} catch {}

const Index = () => {
  const dispatch = useDispatch();
  const model = useSelector((state) => state.global.model);
  const language = useSelector((state) => state.global.language);
  const fileType = useSelector((state) => state.global.fileType);
  const [inputValue, setInputValue] = useState('');
  const [img, setImg] = useState('');
  // 0 loading 1 success 2 fail
  const [uploadState, setUploadState] = useState('0');
  const [callId, setCallId] = useState('');
  const [orcLoading, setOrcLoading] = useState(false);

  useEffect(() => {
    const historyValue = localStorage.getItem('historyValue');
    if (historyValue) {
      inputChange({ target: { value: historyValue } });
    }
  }, []);

  useEffect(() => {
    const clipboard = new Clipboard('#copyAsin');
    return () => {
      clipboard.destroy();
    };
  }, []);

  const load = useCallback(
    _.debounce((e) => uploadInputValue(e), 5000),
    [callId]
  );

  const uploadInputValue = async (value) => {
    if (value && callId) {
      try {
        await submitEditResult({
          call_id: callId,
          edit_result: value,
        });
      } catch {}
    }
  };

  const inputChange = (e) => {
    setInputValue(e.target.value);
    const result = Md.render(e.target.value);
    // window.MathJax.tex2mmlPromise(e.target.value).then((res) => {
    //   const doc = new Document({
    //     sections: [
    //       {
    //         children: [
    //           new Paragraph(res),
    //         ],
    //       },
    //     ],
    //   });
    //   Packer.toBlob(doc).then((buffer) => {
    //     saveAs(buffer, 'test.docx');
    //   });
    // });
    document.getElementById('box').innerHTML = result;
    localStorage.setItem('historyValue', e.target.value);
    load(e.target.value);
  };

  const fileChange = (e) => {
    const sessionId = localStorage.getItem('session_id') || '';
    if (sessionId === '') {
      return showLoginPopup(true);
    }
    uploadFunc(e.file);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(e.file);
    fileReader.onload = function () {
      setImg(this.result);
    };
  };

  useEffect(() => {
    function paste(e) {
      const clipdata = e.clipboardData || window.clipboardData;
      const files = clipdata.files;
      const imgFiles = [];
      if (files.length) {
        for (var i = 0; i < files.length; i++) {
          const IMAGE_MIME_REGEX = /^image\/(p?jpeg|gif|png)$/i;
          if (IMAGE_MIME_REGEX.test(files[i].type)) {
            imgFiles.push(files[i]);
          }
        }
      }
      // 打印图片列表
      if (imgFiles.length) {
        fileChange({ file: imgFiles[0] });
        console.log(model);
        e.preventDefault();
      }
    }

    window.addEventListener('paste', paste);

    return () => {
      window.removeEventListener('paste', paste);
    };
  }, [model, language, fileType]);

  const showLoginPopup = (value = false) => {
    dispatch({
      type: 'global/setShowLogin',
      payload: value,
    });
  };

  const uploadFunc = async (file) => {
    try {
      const sessionId = localStorage.getItem('session_id') || '';
      if (sessionId === '') {
        return showLoginPopup(true);
      }
      setOrcLoading(true);
      const newFile = await imgQuality(file);
      setUploadState('0');
      const formData = new FormData();
      formData.append('image', newFile[0]);
      formData.append('session_id', sessionId);
      formData.append('language', language);
      formData.append('file_type', fileType);
      formData.append('resized_shape', 768);
      const fun = model === 'plus' ? imgOcrGpu : imgOcr;
      const result = await fun(formData);
      if (result.status !== 200) throw result;
      const { status_code, call_id, task_id } = result.data;
      if (status_code === 200) {
        setCallId(call_id);
        getResult(task_id, model);
        setUploadState('1');
      } else {
        throw result;
      }
    } catch (error) {
      console.log(error);
      setUploadState('2');
      setOrcLoading(false);
    }
  };

  const getResult = async (taskId, modelType) => {
    try {
      const fun = modelType === 'pro' ? getTaskResult : getTaskResultGpu;
      const result = await fun({ task_id: taskId, session_id: localStorage.getItem('session_id') });
      if (result.status !== 200) throw result;
      const { status_code, results, plus_quota, status } = result.data;
      if (status === 'PROGRESS') {
        const timer = setTimeout(() => {
          getResult(taskId, modelType);
          clearTimeout(timer);
        }, 1000);
        return;
      }
      if (status_code === 200) {
        dispatch({
          type: 'global/setQuota',
          payload: result.data,
        });
        if (plus_quota <= 0 && modelType === 'plus') {
          Modal.warning({
            title: 'Your Plus Quota is not enough.',
            content: (
              <>
                Visit our <a href="/pricing">Pricing</a> page to recharge.
              </>
            ),
            okText: 'Learn More',
            closable: true,
            onOk: () => {
              window.location.href = '/pricing';
            },
          });
        }
        inputChange({ target: { value: results } });
      } else {
        throw result;
      }
    } catch (error) {
      console.log(error);
    }

    setOrcLoading(false);
  };

  const imgQuality = (file) => {
    const imageCompress = new ImagesQuicklyCompress({
      size: '50kb',
      imageType: file.type,
      quality: 0.8,
      orientation: true,
    });
    return imageCompress.compressor([file]);
  };

  const beforeUpload = () => {
    return false;
  };

  return (
    <div className={styles.indexBox}>
      <div className={styles.index}>
        <Header />
        <div className={styles.inputBox}>
          <div className={styles.introduce}>
            a <span className={styles.introduceHighlight}>Free Alternative</span> to Mathpix
          </div>
          <div className={styles.upload}>
            <div className={styles.dragger} style={{ width: img ? '480px' : '600px' }}>
              <Spin tip="Loading" spinning={orcLoading}>
                <Dragger
                  beforeUpload={beforeUpload}
                  showUploadList={false}
                  onChange={fileChange}
                  accept=".png, .jpg, .jpeg, .webp"
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or copy image into the page for recognition.</p>
                </Dragger>
                <div className={styles.handles}>
                  <Dropdown
                    menu={{
                      items: fileTypeItems.map((item) => {
                        if (item.key === 'page') {
                          item.disabled = model === 'pro';
                        }
                        return item;
                      }),
                      onClick: (e) =>
                        dispatch({
                          type: 'global/setFileType',
                          payload: e.key,
                        }),
                    }}
                    placement="bottomLeft"
                    arrow
                  >
                    <Button ghost>
                      file_type: {fileType}
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </div>
              </Spin>
            </div>
            {img && (
              <div className={styles.uploadImg}>
                <img src={img} alt="" />
                <div className={styles.uploadState}>
                  {uploadState === '0' && (
                    <Spin indicator={<Loading3QuartersOutlined style={{ fontSize: 20 }} spin />} />
                  )}
                  {uploadState === '1' && <CheckOutlined />}
                  {uploadState === '2' && <CloseOutlined />}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.textAreaBox}>
            <TextArea
              className={styles.textArea}
              value={inputValue}
              onChange={inputChange}
              placeholder="Please upload an image or enter your content."
            />
            <div id="copyAsin" data-clipboard-text={inputValue} className={styles.copy}>
              <CopyOutlined />
            </div>
          </div>
          <div className={`${styles.view} ${mdStyles}`} id="box"></div>
        </div>
      </div>
      <div className={styles.bottom}>
        Powered by
        <a target="_blank" rel="noreferrer" href="https://github.com/breezedeus/Pix2Text">
          Pix2Text (P2T)
        </a>
        and
        <a target="_blank" rel="noreferrer" href="https://www.breezedeus.com">
          Breezedeus
        </a>
      </div>
    </div>
  );
};

export default Index;
