import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import aiteImg from '../../assets/images/aite.png';
import mimaImg from '../../assets/images/mima.png';
import cancelImg from '../../assets/images/cancel.png';
import yanzhengmaƒImg from '../../assets/images/yanzhengma.png';
import * as R from 'ramda';
import { sendCode, updatePassword } from '../../services';
import { useSelector, useDispatch } from 'react-redux';
import { message } from 'antd';
const COUNT_DOWN_S = 120;
const COUNT_DOWN_START_MS_KEY = 'count_down_start_ms';

export const UpdatePassword = () => {
  const dispatch = useDispatch();
  const showUpdatePassword = useSelector((state) => state.global.showUpdatePassword);
  const [sendText, setSendText] = useState('send');
  const [form, setform] = useState({
    email: '',
    password: '',
    verification_code: '',
  });

  useEffect(() => {
    const historyCountDown = localStorage.getItem(COUNT_DOWN_START_MS_KEY);
    if (historyCountDown) {
      const historyCountDownDiff = parseInt((new Date().getTime() - historyCountDown) / 1000);
      if (historyCountDownDiff < COUNT_DOWN_S) {
        countDown(COUNT_DOWN_S - historyCountDownDiff);
      } else {
        localStorage.clear(COUNT_DOWN_START_MS_KEY);
      }
    }
  }, []);

  const changeInput = (value, key) => {
    setform((oldV) => {
      return R.assocPath([key], value, oldV);
    });
  };

  const send = async () => {
    try {
      const { email, password } = form;
      if (!(vEmail() && password)) {
        return message.error('Please enter the correct email address and password');
      } else if (sendText !== 'send') {
        return false;
      }
      await sendCode({ email, password });
      countDown();
      message.success('Send successfully');
      localStorage.setItem(COUNT_DOWN_START_MS_KEY, new Date().getTime());
    } catch (error) {
      message.error(error.message || 'Failed to send, please try again');
    }
  };

  const countDown = (s = COUNT_DOWN_S) => {
    let count_down = s;
    const timer = setInterval(function () {
      count_down -= 1;
      if (count_down > 0) {
        setSendText(count_down + 's');
      } else {
        setSendText('send');
        clearInterval(timer);
      }
    }, 1000);
  };

  const showUpdatePasswordPopup = (value = false) => {
    dispatch({
      type: 'global/setShowUpdatePassword',
      payload: value,
    });
  };

  const updatePasswordFunc = async () => {
    try {
      const { email, password, verification_code } = form;
      if (!(vEmail() && password && verification_code)) {
        return message.error('Please enter the correct email address, password and verification code');
      }
      const res = await updatePassword({ email, password, verification_code });
      message.success(res.data.message);
      showUpdatePasswordPopup(false);
    } catch (error) {}
  };

  const vEmail = () => {
    return /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(form.email);
  };

  if (!showUpdatePassword) {
    return null;
  }

  return (
    <div className={styles.loginBox} onClick={() => showUpdatePasswordPopup(false)}>
      <div className={styles.login} onClick={(e) => e.stopPropagation()}>
        <div className={styles.title}>Reset Password</div>
        <div className={styles.inputs}>
          <div className={styles.inputBox}>
            <div>
              <img src={aiteImg} alt="" />
            </div>
            <input
              value={form.email}
              onInput={(e) => changeInput(e.target.value, 'email')}
              type="input"
              placeholder="Email"
            />
          </div>
          <div className={styles.inputBox}>
            <div>
              <img src={mimaImg} alt="" />
            </div>
            <input
              value={form.password}
              onInput={(e) => changeInput(e.target.value, 'password')}
              type="password"
              placeholder="New Password"
            />
          </div>
          <div className={styles.inputBox}>
            <div>
              <img src={yanzhengmaƒImg} alt="" />
            </div>
            <input
              value={form.verification_code}
              onInput={(e) => changeInput(e.target.value, 'verification_code')}
              type="input"
              placeholder="Verification Code"
            />
            <div className={styles.send} onClick={() => send()}>
              {sendText}
            </div>
          </div>
        </div>
        <div className={styles.button} onClick={() => updatePasswordFunc()}>
          Confirm
        </div>
        <img onClick={() => showUpdatePasswordPopup(false)} className={styles.cancel} src={cancelImg} alt="" />
      </div>
    </div>
  );
};
