import { Header } from '../../components/Header';
import styles from './index.module.scss';
import { CheckCircleFilled } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';

const productList = [
  {
    plusQuota: 300000,
    validity: 3,
    price: '$0.99',
    url: 'https://ocr.lemonsqueezy.com/buy/dbc8444a-2fd9-42fe-b6e3-4b2ee4d8f8ed',
  },
  {
    plusQuota: 500000,
    validity: 5,
    price: '$1.49',
    url: 'https://ocr.lemonsqueezy.com/buy/75329ddf-6737-4116-bf95-ca72c4d8e6a9',
  },
  {
    plusQuota: 700000,
    validity: 7,
    price: '$1.99',
    url: 'https://ocr.lemonsqueezy.com/buy/704d9d43-9929-4fe5-b574-845e432bc1d7',
  },
];

const Buy = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.global.userId);

  const jumpBuy = (item) => {
    if (!userId) {
      return dispatch({
        type: 'global/setShowLogin',
        payload: true,
      });
    }
    const url = item.url + '?checkout[custom][user_id]=' + userId;
    window.open(url, '_blank');
  };

  return (
    <div className={styles.price}>
      <Header />

      <div className={styles.content}>
        <div className={styles.introduce}>
          <span className={styles.introduceHighlight}>
            Quota Purchase Options
            <br />
            for the Plus Model
          </span>
        </div>

        <div className={styles.productList}>
          {productList.map((item) => {
            return (
              <div onClick={() => jumpBuy(item)} key={item.price} className={styles.productItemWrap}>
                <div className={styles.productItem}>
                  <div>
                    <CheckCircleFilled className={styles.icon} />
                    Plus Quota: {(item.plusQuota).toLocaleString("zh-CN")}
                  </div>
                  <div>
                    <CheckCircleFilled className={styles.icon} />
                    Validity: {item.validity} Days
                  </div>
                  <div>
                    <CheckCircleFilled className={styles.icon} />
                    Price: {item.price}
                  </div>
                </div>
                <div className={styles.buyBtn}>{item.validity}-Day Power Pack</div>
              </div>
            );
          })}
        </div>

        <div className={styles.hint}><b>注</b>：支持<b>微信</b>、<b>支付宝</b>使用人民币直接付款。</div>
      </div>
    </div>
  );
};

export default Buy;
