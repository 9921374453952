const Agreement = () => {
  return (
    <div style={{ padding: '20px' }}>
      <p>Privacy Policy</p>

      <p>Last Updated: 2023-08-28</p>

      <p>
        This Privacy Policy applies to the P2T Online Service (“the Site”), owned and operated by
        Breezedeus (“We,” “Us,” or “Our”). Please read this Privacy Policy carefully before using
        our website.
      </p>

      <p>
        By using the Site, you agree to be bound by this Privacy Policy, which is incorporated into
        our Terms of Use. If you do not agree with this Privacy Policy, please do not use the Site.
      </p>

      <ol>
        <li>
          User-Generated Content When you upload or post photos to the Site (&quot;User
          Content&quot;), you grant us a worldwide, perpetual, irrevocable, non-exclusive,
          royalty-free license to use, reproduce, modify, publish, list information regarding,
          translate, distribute, syndicate, publicly perform, publicly display, and create
          derivative works of all such User Content and your name, voice, and/or likeness as
          contained in your User Content, in whole or in part, and in any form, media, or
          technology, whether now known or hereafter developed, for use in connection with the Site
          and our (and our successors&#39; and affiliates&#39;) business, including without
          limitation for promoting and redistributing part or all of the Site (and derivative works
          thereof) in any media formats and through any media channels. You also hereby grant each
          user of the Site a non-exclusive license to access your User Content through the Site, and
          to use, reproduce, distribute, display and perform such User Content as permitted through
          the functionality of the Site and under these Terms of Service.{' '}
        </li>
      </ol>

      <p>
        The Site’s owner, including but not limited to, retains the rights to: 1.1 Reproduce and
        resell the photos in an altered state, for example, annotating the images; 1.2 Use the
        images for model training and sell the trained models.
      </p>

      <ol>
        <li>Non-Commercial Use</li>
      </ol>

      <p>
        The Site is for the personal use of individual Users only and may not be used in connection
        with any commercial endeavors. Organizations, companies, or businesses may not use the Site
        for any purpose without express written consent from us. You may not use any information
        obtained from the Site to contact, advertise to, solicit, or sell to any User without their
        prior explicit consent.
      </p>

      <p>
        If you wish to use the Site for commercial purposes, please contact us at
        breezedeus@gmail.com.
      </p>

      <p>
        We reserve the right to modify this Privacy Policy at any time, so please review it
        frequently. If we make significant changes to this policy, we will notify you here, by
        email, or by means of a notice on our home page.
      </p>
      <hr />
      <p>中文版 隐私政策</p>

      <p>最后更新日期：2023-08-28</p>

      <p>
        此隐私政策适用于由 Breezedeus（“我们”、“我们的”、“我们”）拥有和运营的
        P2T网页版（“本站”）。请在使用我们的网站之前仔细阅读此隐私政策。
      </p>

      <p>
        当您使用本站时，您同意受到此隐私政策的约束，该政策已纳入我们的使用条款中。如果您不同意此隐私政策，请不要使用本站。
      </p>

      <ol>
        <li>
          用户生成的内容
          当您向本网站上传或发布照片（以下简称&quot;用户内容&quot;）时，您授予我们一项全球性、永久、不可撤销、非独家、无版税的许可，允许我们在全球范围内使用、复制、修改、发布、列出有关信息、翻译、分发、联合公开展示及创作该用户内容的衍生作品，并使用您在用户内容中的姓名、声音和/或形象，无论是否部分或全部，以及在任何形式、媒介或技术中，无论是现已知晓或日后开发的，均可用于与本网站及我们（及我们的继任者和关联方）的商业活动有关的用途，包括但不限于在任何媒介格式和通过任何媒介渠道中推广和再分发部分或全部的本网站（及其衍生作品）。您还特此授权本网站的每一位用户非独家许可，通过本网站访问您的用户内容，并根据本网站的功能和在本服务条款之下使用、复制、分发、显示和执行此类用户内容。
        </li>
      </ol>

      <p>
        本站的所有者，包括但不限于，保留以下权利： 1.1 修改并再售照片，例如对图像进行注解； 1.2
        使用图像进行模型训练并出售经过训练的模型。
      </p>

      <ol>
        <li>
          非商业性使用
          本网站仅供个人用户的个人使用，不得用于任何商业目的。组织、公司或企业未经我们的明确书面同意，不得出于任何目的使用本网站。未经用户事先明确同意，您不得使用从本网站获得的任何信息来联系、为用户做广告、招揽或销售。
        </li>
      </ol>

      <p>如果您希望出于商业目的使用本网站，请通过 breezedeus@gmail.com 与我们联系。</p>

      <p>
        我们保留随时修改此隐私政策的权利，所以请经常查看。如果我们对此政策做出重大更改，我们会在此处、通过电子邮件或在我们的主页上发布通知来告知您。
      </p>
    </div>
  );
};

export default Agreement;
