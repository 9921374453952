import request from '../utils/request';

export function quota(data) {
  return request({
    url: '/api/quota',
    method: 'post',
    data,
  });
}

// 识别图片
export function imgOcr(data) {
  return request({
    url: '/api/pix2text',
    header: {
      'content-type': 'multipart/form-data',
    },
    method: 'post',
    data,
  });
}

// 识别图片
export function imgOcrGpu(data) {
  return request({
    url: '/api-gpu/pix2text',
    header: {
      'content-type': 'multipart/form-data',
    },
    method: 'post',
    data,
  });
}

// 获取任务结果
export function getTaskResult(data) {
  return request({
    url: '/api/result/' + data.task_id,
    method: 'get',
    params: {
      session_id: data.session_id,
    }
  });
}


// 获取任务结果
export function getTaskResultGpu(data) {
  return request({
    url: '/api-gpu/result/' + data.task_id,
    method: 'get',
    params: {
      session_id: data.session_id,
    }
  });
}

// 上传结果
export function submitEditResult(data) {
  return request({
    url: '/api/submit_edit_result',
    method: 'post',
    data,
  });
}

export function sendCode(data) {
  return request({
    url: '/api/send_mail_code',
    method: 'post',
    data,
  });
}

export function register(data) {
  return request({
    url: '/api/register',
    method: 'post',
    data,
  });
}

export function login(data) {
  return request({
    url: '/api/login',
    method: 'post',
    data,
  });
}

export function updatePassword(data) {
  return request({
    url: '/api/update_password',
    method: 'post',
    data,
  });
}
