import IndexPage from '../pages/Index/index';
import PricingPage from '../pages/Pricing/index';
import AgreementPage from '../pages/Agreement/index';

const router = [
  {
    path: '/agreement',
    name: 'Pix2Text (P2T) - Free Mathpix Alternative - agreement',
    component: AgreementPage,
  },
  {
    path: '/pricing',
    name: 'Pix2Text (P2T) - Free Mathpix Alternative - buy',
    component: PricingPage,
  },
  {
    path: '/',
    exact: true,
    name: 'Pix2Text (P2T) - Free Mathpix Alternative',
    component: IndexPage,
  },
];

export default router;
