export const models = [
  {
    key: 'pro',
    label: 'Pro',
  },
  {
    key: 'plus',
    label: 'Plus',
  },
];

export const languages = [
  {
    key: 'English',
    label: 'English',
  },
  {
    key: 'Simplified Chinese',
    label: 'Simplified Chinese',
  },
  {
    key: 'Traditional Chinese',
    label: 'Traditional Chinese',
  },
  {
    key: 'Hindi',
    label: 'Hindi',
  },
  {
    key: 'Spanish',
    label: 'Spanish',
  },
  {
    key: 'Vietnamese',
    label: 'Vietnamese',
  },
  {
    key: 'German',
    label: 'German',
  },
  {
    key: 'Danish',
    label: 'Danish',
  },
  {
    key: 'Italian',
    label: 'Italian',
  },
  {
    key: 'French',
    label: 'French',
  },
  {
    key: 'Russian',
    label: 'Russian',
  },
];

export const fileTypeItems = [
  {
    key: 'page',
    label: 'page',
  },
  {
    key: 'text_formula',
    label: 'text_formula',
  },
  {
    key: 'formula',
    label: 'formula',
  },
  {
    key: 'text',
    label: 'text',
  },
];
