import { Route } from 'react-router-dom';

export const renderRoutes = (routerConfig) =>
  (routerConfig || []).map(({ component: Component, children, name, ...routeProps }) => (
    <Route
      key={name}
      {...routeProps}
      render={(props) => {
        document.title = name;
        return <Component {...props} routes={children} />;
      }}
    />
  ));
