import { useEffect, useState } from 'react';
import { Menu } from 'antd';
import dayjs from 'dayjs';
import { DownOutlined } from '@ant-design/icons';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { quota } from '../../services/index';
import homeImg from '../../assets/images/home2.png';
import loginImg from '../../assets/images/login.png';
import styles from './index.module.scss';
import { models, languages } from '../../pages/Index/config';
import { Dropdown, Space, Divider, Button, Tag } from 'antd';

export const Header = () => {
  const history = useHistory();
  const location = useLocation();
  const [current, setCurrent] = useState('index');
  const dispatch = useDispatch();
  const model = useSelector((state) => state.global.model);
  const language = useSelector((state) => state.global.language);
  const quotaData = useSelector((state) => state.global.quota);
  const userId = useSelector((state) => state.global.userId);
  const showLogin = useSelector((state) => state.global.showLogin);

  const ocrConfig = [
    {
      label: (
        <div className={styles.b}>
          <Dropdown
            menu={{
              items: models.map((item) => {
                item.disabled = item.key === 'plus' && quotaData.plus_quota <= 0;
                return item;
              }),
              onClick: (e) =>
                dispatch({
                  type: 'global/setModel',
                  payload: e.key,
                }),
            }}
            dropdownRender={(menus) => {
              return (
                <div className={styles.modelsDropdown}>
                  <div>{menus}</div>
                  <Tag
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push('/pricing');
                    }}
                    className={styles.tag}
                    color="#108ee9"
                  >
                    recharge
                  </Tag>
                </div>
              );
            }}
          >
            <Space>
              Model: <span className={styles.l}>{models.find((item) => item.key === model)?.label || ''}</span>
              <DownOutlined />
            </Space>
          </Dropdown>
        </div>
      ),
      key: 'models',
    },
    {
      label: (
        <div className={styles.b}>
          <Dropdown
            menu={{
              items: languages.map((item, index) => {
                item.disabled = !(index <= 1 || model === 'plus');
                return item;
              }),
              onClick: (e) => {
                dispatch({
                  type: 'global/setLanguage',
                  payload: e.key,
                });
              },
            }}
          >
            <Space>
              Language: <span className={styles.l}>{languages.find((item) => item.key === language)?.label || ''}</span>
              <DownOutlined />
            </Space>
          </Dropdown>
        </div>
      ),
      key: 'languages',
    },
  ];

  const items = [
    {
      label: <div className={styles.b}>Home</div>,
      key: 'index',
      path: '/',
    },
    {
      label: (
        <div className={styles.b}>
          <a href="https://www.breezedeus.com/article/pix2text" target="_blank" rel="noopener noreferrer">
            Models
          </a>
        </div>
      ),
      key: 'getModels',
    },
    {
      label: <div className={styles.b}>Pricing</div>,
      key: 'pricing',
      path: '/pricing',
    },
    ...(current === 'index' ? ocrConfig : []),
  ];

  useEffect(() => {
    setCurrent(items.find((item) => item.path === location.pathname)?.key || items[0].key);
  }, []);

  useEffect(() => {
    if (!showLogin) {
      getQuota();
    }
  }, [showLogin]);

  const getQuota = async () => {
    try {
      const session_id = localStorage.getItem('session_id') || '';
      if (session_id) {
        const res = await quota({ session_id });
        const { plus_quota } = res.data;
        dispatch({
          type: 'global/setQuota',
          payload: res.data,
        });
        dispatch({
          type: 'global/setUserId',
          payload: res.data.user.id,
        });
        // 优先使用plus
        dispatch({
          type: 'global/setModel',
          payload: plus_quota > 0 ? 'plus' : 'pro',
        });
        dispatch({
          type: 'global/setLanguage',
          payload: languages[1].key,
        });
      }
    } catch {}
  };

  const logout = () => {
    dispatch({
      type: 'global/logout',
    });
  };

  const loginBtn = () => {
    dispatch({
      type: 'global/setShowLogin',
      payload: true,
    });
  };

  const onClickMenu = (e) => {
    const path = items.find((item) => item.key === e.key)?.path;
    path && history.push(path);
  };

  return (
    <div className={styles.index}>
      <div className={styles.headerBox}>
        <div className={styles.headerBox2}>
          <div className={styles.header}>
            <a target="_block" href="https://www.breezedeus.com">
              <img src={homeImg} alt="" />
            </a>
            <div className={styles.septalLine} />
            <div className={styles.logo}>
              Pix2Text<span className={styles.beta}>beta</span>
            </div>
            <div className={styles.subtitle}>
              <Menu
                className={styles.menu}
                onClick={onClickMenu}
                selectedKeys={[current]}
                mode="horizontal"
                items={items}
              />
            </div>
            {userId !== null && (
              <div className={styles.login}>
                <div className={styles.userInfo}>
                  <div className={styles.quota}>
                    <div className={styles.quotaTitle}>Quota</div>
                    <div className={styles.quotaContent}>
                      <div>
                        <span>Plus: </span>
                        <div>{quotaData.plus_quota}</div>
                        <div>Expires on {dayjs(quotaData.plus_expiry_date).format('YYYY-MM-DD')}</div>
                      </div>
                      <div>
                        <span>Pro: </span>
                        <div>{quotaData.pro_quota}</div>
                        <div>Expires on {dayjs(quotaData.pro_expiry_date).format('YYYY-MM-DD')}</div>
                      </div>
                    </div>
                  </div>
                  <Divider style={{ margin: '10px 0', borderColor: '#d9d9d9' }} />
                  <div onClick={() => history.push('/pricing')} className={styles.updatePassword}>
                    <Button type="primary" ghost block>
                      Recharge Plus
                    </Button>
                  </div>
                  <div
                    onClick={() =>
                      dispatch({
                        type: 'global/setShowUpdatePassword',
                        payload: true,
                      })
                    }
                    className={styles.updatePassword}
                  >
                    <Button block>Update Password</Button>
                  </div>
                  <div onClick={() => logout()} className={styles.logout}>
                    <Button block>Log out</Button>
                  </div>
                </div>
                <img className={styles.user} src={loginImg} alt="" />
              </div>
            )}
            {userId === null && (
              <div className={styles.login} onClick={loginBtn}>
                <Button ghost type="primary">
                  Log in
                </Button>
              </div>
            )}
            <div style={{ marginTop: '10px' }} className="sharethis-inline-follow-buttons"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
