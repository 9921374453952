import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { renderRoutes } from './router/index';
import routerConfig from './router/config';
import { Provider } from 'react-redux';
import store from './store';
import './index.css';
import { Login } from './components/Login';
import { UpdatePassword } from './components/UpdatePassword';

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <Login />
    <UpdatePassword />
    <BrowserRouter>{renderRoutes(routerConfig)}</BrowserRouter>
  </Provider>
);

reportWebVitals();
